import React, { useState} from 'react';
import { Container, Typography, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { _CreateLogin } from '../apicalls/_createlogin';
import { _CreateProfile } from '../apicalls/_createProfile';

export default function CreateAccoount() {
    const navigate = useNavigate();

    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');

    const [buttonEnabled, setButtonEnabled] = useState(true);
    const [errors, setErrors] = useState<PasswordErrors>({});
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    function onSubmit() {
        // console.log('Submit');

        if (fname.length > 0) {
            // console.log('First Name :', fname);
            if (lname.length > 0) {
                // console.log('Last Name :', lname);
                if (validateEmail(email)) {
                    // console.log('Email :', email);
                    if (validatePassword(password)) {
                        // console.log('Password :', password);
                        if (password2 === password) {
                            // console.log('Password2 :', password2);
                            // console.log('Save Data');
                            _CreateLogin(fname, lname, email, password).then((result) => {
                                // console.log(result);
                                var _userId = result;
                                if (result) {
                                    _CreateProfile(_userId, fname, lname, email).then((result) => {
                                        // console.log(result);
                                        if (result === 'Created') {
                                            navigate('/login');
                                        }
                                        else {
                                            // console.log('Failed to create profile');
                                        }   
                                    });   
                                } else {
                                    // console.log('Failed to create login');
                                }
                            }); 
                        }
                    }
                }
            }
        }
        else {
            // console.log('Invalid Data');
        }
    };

    interface PasswordErrors {
        length?: string;
        uppercase?: string;
        lowercase?: string;
        number?: string;
        specialChar?: string;
    }

    const validatePassword = (password: string): boolean => {
        const errors: PasswordErrors = {};

        if (password.length < 8) {
            errors.length = 'Password must be at least 8 characters';
        }
        if (!/[A-Z]/.test(password)) {
            errors.uppercase = 'Password must contain an uppercase letter';
        }
        if (!/[a-z]/.test(password)) {
            errors.lowercase = 'Password must contain a lowercase letter';
        }
        if (!/[0-9]/.test(password)) {
            errors.number = 'Password must contain a number';
        }
        if (!/[^A-Za-z0-9\s]/.test(password)) {
            errors.specialChar = 'Password must contain a special character';
        }
        setErrors(errors);
        // console.log('Password Errors :', errors);
        return Object.keys(errors).length === 0;
    };

    function validateEmail(email: string): boolean {
        return isValidEmail.test(email);
    }

    return (
        <Container sx={{ height: "100vh"}}>
            <Typography variant="h4">Create Account</Typography>
            <Typography variant="body1">
                Create an account with basic information to get started.
            </Typography>
            <Typography variant="body1">
                You can fill out your profile after creating an account.
            </Typography>
            <Typography variant="body1"></Typography>
            <TextField
                id="fname"
                label="First Name"
                type="text"
                required
                fullWidth
                margin="normal"
                value={fname}
                onChange={(e) => setFName(e.target.value)}
            />
            <TextField
                id="lname"
                label="Last Name"
                type="text"
                required
                fullWidth
                margin="normal"
                value={lname}
                onChange={(e) => setLName(e.target.value)}
            />
            <TextField
                id="email"
                label="Email"
                type="email"
                required
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                id="password"
                label="Password"
                type="password"
                required
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
                id="password2"
                label="Confirm Password"
                type="password"
                required
                fullWidth
                margin="normal"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
            />
            <Button variant="contained" color="primary" onClick={onSubmit} disabled={!buttonEnabled}>Submit</Button>

        </Container>
    );
}