export async function _SaveProfile(
                            userId: string,
                            fname: string,
                            lname: string,
                            email: string,
                            add1: string,
                            add2: string,
                            city: string,
                            state: string,
                            postcode: string,
                            country: string,
                            telephone: string) 
{
    // console.log('Creating Account');  

    // console.log(userId);
    // console.log(fname);
    // console.log(lname);
    // console.log(email);
    // console.log(add1);
    // console.log(add2);
    // console.log(city);
    // console.log(state);
    // console.log(postcode);
    // console.log(country);
    // console.log(telephone);
    


    var _body = JSON.stringify({ userId: userId, 
                                    fname: fname, 
                                    lname: lname, 
                                    email: email,
                                    add1: add1, 
                                    add2: add2,
                                    city: city,
                                    state: state,
                                    postcode: postcode,
                                    country: country,
                                    telephone: telephone });

    // console.log(_body);

    const response = await fetch('https://api.splinge.com/profilesave', {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: _body
    });

    if (response.ok) {
        const data = await response.json();
        // console.log(data);
        return data;
    } else {
        // console.log('Failed to create account');
        return null;
    }
}