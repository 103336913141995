import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import {useStore} from '../store/store';

export default function Footer() {
    const navigate = useNavigate();

    const isLoggedIn = useStore(state => state.isLoggedIn);

    const [TOSopen, setTOSOpen] = useState(false);
    const [PrivacyOpen, setPrivacyOpen] = useState(false);

    const handleTOS = async () => {
        const response = await fetch('./documents/tos.txt');
        const text = await response.text();
        setTOSOpen(true);
    };

    const handlePrivacy = async () => {
        const response = await fetch('./docs/privacy.txt');
        const text = await response.text();
        setPrivacyOpen(true);
    };


    const handleTOSClose = () => {
        setTOSOpen(false);
    };

    const handlePrivacyClose = () => {
        setPrivacyOpen(false);
    }


    const handleLogin = () => {
        navigate(`/Login`);
    };

    return (
        <>
            <Dialog open={TOSopen} onClose={handleTOSClose}>
                <DialogTitle>Terms of Service</DialogTitle>
                <DialogContent>
                    <Container>
                        <Typography variant="body1"><b>Terms and Conditions</b> </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">Agreement between User and Splinge, Inc (Splinge) </Typography>
                        <Typography variant="body1">&nbsp; </Typography>
                        <Typography variant="body1">
                            Welcome to Splinge. The website (the
                            &quot;Site&quot;) is comprised of various web pages operated by Splinge. The site content and apps are offered to you conditioned on your
                            acceptance without modification of the terms, conditions, and notices contained
                            herein (the &quot;Terms&quot;). Your use of the Site constitutes your
                            agreement to all such Terms. Please read these terms carefully, and keep a copy
                            of them for your reference.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">Splinge provides content and Software as a Service. </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">_________________ </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Privacy</b> </Typography>

                        <Typography variant="body1">
                            Your use of the Site is subject to Splinge's Privacy
                            Policy. Please review our Privacy Policy, which also governs the Site and
                            informs users of our data collection practices.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            <b>Electronic
                                Communications</b>
                        </Typography>

                        <Typography variant="body1">
                            Visiting the Site or sending emails to Splinge
                            constitutes electronic communications. You consent to receive electronic
                            communications and you agree that all agreements, notices, disclosures and
                            other communications that we provide to you electronically, via email and on
                            the Site, satisfy any legal requirement that such communications be in writing.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Your Account</b> </Typography>

                        <Typography variant="body1">
                            If you use this site, you are responsible for maintaining
                            the confidentiality of your account and password and for restricting access to
                            your computer, and you agree to accept responsibility for all activities that
                            occur under your account or password. You may not assign or otherwise transfer
                            your account to any other person or entity. You acknowledge that Splinge is not
                            responsible for third party access to your account that results from theft or
                            misappropriation of your account. Splinge and its associates reserve the right
                            to refuse or cancel service, terminate accounts, or remove or edit content at
                            our sole discretion.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Children Under Thirteen</b> </Typography>

                        <Typography variant="body1">
                            Splinge does not knowingly collect, either online or
                            offline, personal information from persons under the age of thirteen. If you
                            are under 21, you may use the Site only with permission of a parent or
                            guardian.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Links to Third Party Sites/Third Party Services</b> </Typography>

                        <Typography variant="body1">
                            The Site may contain links to other websites
                            (&quot;Linked Sites&quot;). The Linked Sites are not under the control of
                            Splinge and Splinge is not responsible for the contents of any Linked Site,
                            including without limitation any link contained in a Linked Site, or any
                            changes or updates to a Linked Site. Splinge is providing these links to you
                            only as a convenience, and the inclusion of any link does not imply endorsement
                            by Splinge of the site or any association with its operators.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Certain services made available via the Site are
                            delivered by third party sites and organizations. By using any product, service
                            or functionality originating from the the Site, you hereby acknowledge
                            and consent that Splinge may share such information and data with any third
                            party with whom Splinge has a contractual relationship to provide the requested
                            product, service or functionality on behalf of the Site users and
                            customers.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>No Unlawful or Prohibited Use/Intellectual Property </b></Typography>

                        <Typography variant="body1">
                            You are granted a non-exclusive, non-transferable, revocable
                            license to access and use the Site strictly in accordance with these
                            terms of use. As a condition of your use of the Site, you warrant to Splinge
                            that you will not use the Site for any purpose that is unlawful or prohibited
                            by these Terms. You may not use the Site in any manner which could damage,
                            disable, overburden, or impair the Site or interfere with any other party's use
                            and enjoyment of the Site. You may not obtain or attempt to obtain any
                            materials or information through any means not intentionally made available or
                            provided for through the Site.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            All content included as part of the Service, such as text,
                            graphics, logos, images, as well as the compilation thereof, and any software
                            used on the Site, is the property of Splinge or its suppliers and protected by
                            copyright and other laws that protect intellectual property and proprietary
                            rights. You agree to observe and abide by all copyright and other proprietary
                            notices, legends or other restrictions contained in any such content and will
                            not make any changes thereto.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            You will not modify, publish, transmit, reverse engineer,
                            participate in the transfer or sale, create derivative works, or in any way
                            exploit any of the content, in whole or in part, found on the Site. Splinge
                            content is not for resale. Your use of the Site does not entitle you to make
                            any unauthorized use of any protected content, and in particular you will not
                            delete or alter any proprietary rights or attribution notices in any content.
                            You will use protected content solely for your personal use, and will make no
                            other use of the content without the express written permission of Splinge and
                            the copyright owner. You agree that you do not acquire any ownership rights in
                            any protected content. We do not grant you any licenses, express or implied, to
                            the intellectual property of Splinge or our licensors except as expressly
                            authorized by these Terms.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            <b>Use of Communication Services</b>
                        </Typography>

                        <Typography variant="body1">
                            The Site may contain bulletin board services, chat areas,
                            news groups, forums, communities, personal web pages, calendars, and/or other
                            message or communication facilities designed to enable you to communicate with
                            the public at large or with a group (collectively, &quot;Communication
                            Services&quot;). You agree to use the Communication Services only to post, send
                            and receive messages and material that are proper and related to the particular
                            Communication Service.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            By way of example, and not as a limitation, you agree that
                            when using a Communication Service, you will not: defame, abuse, harass, stalk,
                            threaten or otherwise violate the legal rights (such as rights of privacy and
                            publicity) of others; publish, post, upload, distribute or disseminate any inappropriate,
                            profane, defamatory, infringing, obscene, indecent or unlawful topic, name,
                            material or information; upload files that contain software or other material
                            protected by intellectual property laws (or by rights of privacy of publicity)
                            unless you own or control the rights thereto or have received all necessary
                            consents; upload files that contain viruses, corrupted files, or any other
                            similar software or programs that may damage the operation of another's
                            computer; advertise or offer to sell or buy any goods or services for any
                            business purpose, unless such Communication Service specifically allows such
                            messages; conduct or forward surveys, contests, pyramid schemes or chain
                            letters; download any file posted by another user of a Communication Service
                            that you know, or reasonably should know, cannot be legally distributed in such
                            manner; falsify or delete any author attributions, legal or other proper
                            notices or proprietary designations or labels of the origin or source of
                            software or other material contained in a file that is uploaded; restrict or
                            inhibit any other user from using and enjoying the Communication Services;
                            violate any code of conduct or other guidelines which may be applicable for any
                            particular Communication Service; harvest or otherwise collect information
                            about others, including e-mail addresses, without their consent; violate any
                            applicable laws or regulations.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Splinge has no obligation to monitor the Communication
                            Services. However, Splinge reserves the right to review materials posted to a
                            Communication Service and to remove any materials in its sole discretion.
                            Splinge reserves the right to terminate your access to any or all of the
                            Communication Services at any time without notice for any reason whatsoever.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Splinge reserves the right at all times to disclose any
                            information as necessary to satisfy any applicable law, regulation, legal
                            process or governmental request, or to edit, refuse to post or to remove any
                            information or materials, in whole or in part, in Splinge's sole discretion.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Always use caution when giving out any personally
                            identifying information about yourself or your children in any Communication
                            Service. Splinge does not control or endorse the content, messages or
                            information found in any Communication Service and, therefore, Splinge
                            specifically disclaims any liability with regard to the Communication Services
                            and any actions resulting from your participation in any Communication Service.
                            Managers and hosts are not authorized Splinge spokespersons, and their views do
                            not necessarily reflect those of Splinge.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Materials uploaded to a Communication Service may be subject
                            to posted limitations on usage, reproduction and/or dissemination. You are
                            responsible for adhering to such limitations if you upload the materials.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            <b>Materials Provided to
                                the Site or Posted on the Site</b>
                        </Typography>

                        <Typography variant="body1">
                            Splinge does not claim ownership of the materials you
                            provide to the Site (including feedback and suggestions) or post,
                            upload, input or submit to the Site or our associated services
                            (collectively &quot;Submissions&quot;). However, by posting, uploading,
                            inputting, providing or submitting your Submission you are granting Splinge,
                            our affiliated companies and necessary sublicensees permission to use your Submission
                            in connection with the operation of their Internet businesses including,
                            without limitation, the rights to: copy, distribute, transmit, publicly
                            display, publicly perform, reproduce, edit, translate and reformat your
                            Submission; and to publish your name in connection with your Submission.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            No compensation will be paid with respect to the use of your
                            Submission, as provided herein. Splinge is under no obligation to post or use
                            any Submission you may provide and may remove any Submission at any time in
                            Splinge's sole discretion.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            By posting, uploading, inputting, providing or submitting
                            your Submission you warrant and represent that you own or otherwise control all
                            of the rights to your Submission as described in this section including,
                            without limitation, all the rights necessary for you to provide, post, upload,
                            input or submit the Submissions.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            <b>International Users</b>
                        </Typography>

                        <Typography variant="body1">
                            The Service is controlled, operated and administered by
                            Splinge from our offices within the USA. If you access the Service from a
                            location outside the USA, you are responsible for compliance with all local
                            laws. You agree that you will not use the Site content in any country or in any manner prohibited by any applicable
                            laws, restrictions or regulations.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Indemnification</b> </Typography>

                        <Typography variant="body1">
                            You agree to indemnify, defend and hold harmless Splinge,
                            its officers, directors, employees, agents and third parties, for any losses,
                            costs, liabilities and expenses (including reasonable attorney's fees) relating
                            to or arising out of your use of or inability to use the Site or services, any
                            user postings made by you, your violation of any terms of this Agreement or
                            your violation of any rights of a third party, or your violation of any
                            applicable laws, rules or regulations. Splinge reserves the right, at its own
                            cost, to assume the exclusive defense and control of any matter otherwise
                            subject to indemnification by you, in which event you will fully cooperate with
                            Splinge in asserting any available defenses.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Arbitration</b> </Typography>

                        <Typography variant="body1">
                            In the event the parties are not able to resolve any dispute
                            between them arising out of or concerning these Terms and Conditions, or any
                            provisions hereof, whether in contract, tort, or otherwise at law or in equity
                            for damages or any other relief, then such dispute shall be resolved only by
                            final and binding arbitration pursuant to the Federal Arbitration Act,
                            conducted by a single neutral arbitrator and administered by the American
                            Arbitration Association, or a similar arbitration service selected by the
                            parties, in a location mutually agreed upon by the parties. The arbitrator's
                            award shall be final, and judgment may be entered upon it in any court having
                            jurisdiction. In the event that any legal or equitable action, proceeding or
                            arbitration arises out of or concerns these Terms and Conditions, the
                            prevailing party shall be entitled to recover its costs and reasonable
                            attorney's fees. The parties agree to arbitrate all disputes and claims in
                            regards to these Terms and Conditions or any disputes arising as a result of
                            these Terms and Conditions, whether directly or indirectly, including Tort
                            claims that are a result of these Terms and Conditions. The parties agree that
                            the Federal Arbitration Act governs the interpretation and enforcement of this
                            provision. The entire dispute, including the scope and enforceability of this
                            arbitration provision shall be determined by the Arbitrator. This arbitration
                            provision shall survive the termination of these Terms and Conditions.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            <b>Class Action Waiver</b>
                        </Typography>

                        <Typography variant="body1">
                            Any arbitration under these Terms and Conditions will take
                            place on an individual basis; class arbitrations and
                            class/representative/collective actions are not permitted. THE PARTIES AGREE
                            THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL
                            CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS,
                            COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE
                            ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and Splinge
                            agree otherwise, the arbitrator may not consolidate more than one person's
                            claims, and may not otherwise preside over any form of a representative or
                            class proceeding.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            <b>Liability Disclaimer</b>
                        </Typography>

                        <Typography variant="body1">
                            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED
                            IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL
                            ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. Splinge.
                            AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY
                            TIME.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Splinge AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT
                            THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE
                            INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE
                            SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL
                            SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE
                            PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OR CONDITION OF ANY KIND. Splinge
                            INC. AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH
                            REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,
                            INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR
                            A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO
                            EVENT SHALL Splinge. AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT,
                            INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
                            WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR
                            PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF
                            THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE
                            PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE,
                            PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE
                            ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT,
                            NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF Splinge OR ANY OF ITS
                            SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
                            STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
                            CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
                            IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE
                            TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            <b>Termination/Access
                                Restriction </b>
                        </Typography>

                        <Typography variant="body1">
                            Splinge reserves the right, in its sole discretion, to
                            terminate your access to the Site and the related services or any portion
                            thereof at any time, without notice. To the maximum extent permitted by law,
                            this agreement is governed by the laws of the State of Colorado and you hereby
                            consent to the exclusive jurisdiction and venue of courts in Colorado in all
                            disputes arising out of or relating to the use of the Site. Use of the Site is
                            unauthorized in any jurisdiction that does not give effect to all provisions of
                            these Terms, including, without limitation, this section.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            You agree that no joint venture, partnership, employment, or
                            agency relationship exists between you and Splinge as a result of this
                            agreement or use of the Site. Splinge's performance of this agreement is
                            subject to existing laws and legal process, and nothing contained in this
                            agreement is in derogation of Splinge's right to comply with governmental,
                            court and law enforcement requests or requirements relating to your use of the
                            Site or information provided to or gathered by Splinge with respect to such
                            use. If any part of this agreement is determined to be invalid or unenforceable
                            pursuant to applicable law including, but not limited to, the warranty
                            disclaimers and liability limitations set forth above, then the invalid or
                            unenforceable provision will be deemed superseded by a valid, enforceable
                            provision that most closely matches the intent of the original provision and
                            the remainder of the agreement shall continue in effect.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Unless otherwise specified herein, this agreement
                            constitutes the entire agreement between the user and Splinge with respect to
                            the Site and it supersedes all prior or contemporaneous communications and
                            proposals, whether electronic, oral or written, between the user and Splinge
                            with respect to the Site. A printed version of this agreement and of any notice
                            given in electronic form shall be admissible in judicial or administrative
                            proceedings based upon or relating to this agreement to the same extent and
                            subject to the same conditions as other business documents and records
                            originally generated and maintained in printed form. It is the express wish to
                            the parties that this agreement and all related documents be written in English.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Changes to Terms</b> </Typography>

                        <Typography variant="body1">
                            Splinge reserves the right, in its sole discretion, to
                            change the Terms under which the Site is offered. The most current
                            version of the Terms will supersede all previous versions. Splinge encourages
                            you to periodically review the Terms to stay informed of our updates.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Contact Us</b> </Typography>

                        <Typography variant="body1">
                            Splinge welcomes your questions or comments regarding the
                            Terms:
                        </Typography>

                        <a href="contact">Comments Form</a>

                        <Typography variant="body1">Effective as of Mar 1, 2025 </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleTOSClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={PrivacyOpen} onClose={handlePrivacyClose}>
                <DialogTitle>Privacy</DialogTitle>
                <DialogContent>

                    <Container>
                        <Typography variant="body1">
                            Protecting your private information is our priority. This
                            Statement of Privacy applies to Splinge, Inc ("Splinge") and all of its web sites, content and apps. It governs
                            data collection and usage. For the purposes of this Privacy Policy, unless
                            otherwise noted, all references to Splinge, including all its web content and apps. The Splinge website is a App Store site. By using the Splinge website,
                            you consent to the data practices described in this statement.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Collection of your Personal Information</b> </Typography>

                        <Typography variant="body1">
                            In order to better provide you with products and services
                            offered on our Site, Splinge may collect personally identifiable information,
                            such as your:
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">&nbsp;-          First and Last Name </Typography>

                        <Typography variant="body1">&nbsp;-          E-mail Address </Typography>

                        <Typography variant="body1">&nbsp;-          Phone Number </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Splinge may also collect anonymous demographic information,
                            which is not unique to you, such as your:
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">&nbsp;-          Age </Typography>

                        <Typography variant="body1">&nbsp;-          Gender </Typography>

                        <Typography variant="body1">&nbsp;-          Race </Typography>

                        <Typography variant="body1">&nbsp;-          Household Income </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Please keep in mind that if you directly disclose personally
                            identifiable information or personally sensitive data through Splinge's public
                            message boards, this information may be collected and used by others.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            We do not collect any personal information about you unless
                            you voluntarily provide it to us. However, you may be required to provide
                            certain personal information to us when you elect to use certain products or
                            services available on our sites and apps. These may include: (a) registering for an
                            account on our sites, or apps; (b) entering a sweepstakes or contest sponsored by us or
                            one of our partners; (c) signing up for special offers from selected third
                            parties; (d) sending us an email message; (e) submitting your credit card or
                            other payment information when ordering and purchasing products and services on
                            our sites and apps. To wit, we will use your information for, but not limited to,
                            communicating with you in relation to services and/or products you have
                            requested from us. We also may gather additional personal or non-personal
                            information in the future.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Use of your Personal Information </b></Typography>

                        <Typography variant="body1">
                            Splinge collects and uses your personal information to
                            operate its website(s) and deliver the services you have requested.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Splinge may also use your personally identifiable
                            information to inform you of other products or services available from Splinge
                            and its affiliates.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Sharing Information with Third Parties</b> </Typography>

                        <Typography variant="body1">
                            Splinge does not sell, rent or lease its customer lists to
                            third parties.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Splinge may, from time to time, contact you on behalf of
                            external business partners about a particular offering that may be of interest
                            to you. In those cases, your unique personally identifiable information
                            (e-mail, name, address, telephone number) is transferred to the third party.
                            Splinge may share data with trusted partners to help perform statistical
                            analysis, send you email or postal mail, provide customer support, or arrange
                            for deliveries. All such third parties are prohibited from using your personal
                            information except to provide these services to Splinge, and they are required
                            to maintain the confidentiality of your information.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            Splinge may disclose your personal information, without
                            notice, if required to do so by law or in the good faith belief that such
                            action is necessary to: (a) conform to the edicts of the law or comply with
                            legal process served on Splinge or the site; (b) protect and defend the rights
                            or property of Splinge; and/or (c) act under exigent circumstances to protect
                            the personal safety of users of Splinge, or the public.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Tracking User Behavior</b> </Typography>

                        <Typography variant="body1">
                            Splinge may keep track of the websites and pages our users
                            visit within Splinge, in order to determine what Splinge services are the most
                            popular. This data is used to deliver customized content and advertising within
                            Splinge to customers whose behavior indicates that they are interested in a
                            particular subject area.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Automatically Collected Information</b> </Typography>

                        <Typography variant="body1">
                            Information about your computer hardware and software may be
                            automatically collected by Splinge. This information can include: your IP
                            address, bcontainerser type, domain names, access times and referring website
                            addresses. This information is used for the operation of the service, to maintain
                            quality of the service, and to provide general statistics regarding use of the
                            Splinge website.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Use of Cookies</b> </Typography>

                        <Typography variant="body1">
                            The Splinge website may use &quot;cookies&quot; to help you
                            personalize your online experience. A cookie is a text file that is placed on
                            your hard disk by a web page server. Cookies cannot be used to run programs or
                            deliver viruses to your computer. Cookies are uniquely assigned to you, and can
                            only be read by a web server in the domain that issued the cookie to you.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            One of the primary purposes of cookies is to provide a
                            convenience feature to save you time. The purpose of a cookie is to tell the
                            Web server that you have returned to a specific page. For example, if you
                            personalize Splinge pages, or register with Splinge site or services, a cookie
                            helps Splinge to recall your specific information on subsequent visits. This
                            simplifies the process of recording your personal information, such as billing
                            addresses, shipping addresses, and so on. When you return to the same Splinge
                            website, the information you previously provided can be retrieved, so you can
                            easily use the Splinge features that you customized.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            You have the ability to accept or decline cookies. Most Web
                            bcontainersers automatically accept cookies, but you can usually modify your bcontainerser
                            setting to decline cookies if you prefer. If you choose to decline cookies, you
                            may not be able to fully experience the interactive features of the Splinge
                            services or websites you visit.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Security of your Personal Information</b> </Typography>

                        <Typography variant="body1">
                            Splinge secures your personal information from unauthorized
                            access, use, or disclosure. Splinge uses the following methods for this
                            purpose:
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">&nbsp;-          SSL Protocol </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            When personal information (such as a credit card number) is
                            transmitted to other websites, it is protected through the use of encryption,
                            such as the Secure Sockets Layer (SSL) protocol.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            We strive to take appropriate security measures to protect
                            against unauthorized access to or alteration of your personal information.
                            Unfortunately, no data transmission over the Internet or any wireless network
                            can be guaranteed to be 100% secure. As a result, while we strive to protect
                            your personal information, you acknowledge that: (a) there are security and
                            privacy limitations inherent to the Internet which are beyond our control; and
                            (b) security, integrity, and privacy of any and all information and data
                            exchanged between you and us through this Site cannot be guaranteed.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Children Under Thirteen</b> </Typography>

                        <Typography variant="body1">
                            Splinge does not knowingly collect personally identifiable
                            information from children under the age of thirteen. If you are under the age
                            of thirteen, you must ask your parent or guardian for permission to use this
                            website.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            <b>Opt-Out &amp; Unsubscribe from Third Party Communications</b>
                        </Typography>

                        <Typography variant="body1">
                            We respect your privacy and give you an opportunity to
                            opt-out of receiving announcements of certain information. Users may opt-out of
                            receiving any or all communications from third-party partners of Splinge by
                            contacting us here:
                        </Typography>

                        <Typography variant="body1">- Email: optout@BrownBoxSoftware.com </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>E-mail Communications</b> </Typography>

                        <Typography variant="body1">
                            From time to time, Splinge may contact you via email for the
                            purpose of providing announcements, promotional offers, alerts, confirmations,
                            surveys, and/or other general communication. In order to improve our Services,
                            we may receive a notification when you open an email from Splinge or click on a
                            link therein.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1">
                            If you would like to stop receiving marketing or promotional
                            communications via email from Splinge, you may opt out of such communications
                            by visiting www.Splinge.com/optout.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>External Data Storage Sites</b> </Typography>

                        <Typography variant="body1">
                            We may store your data on servers provided by third party
                            hosting vendors with whom we have contracted.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Changes to this Statement</b> </Typography>

                        <Typography variant="body1">
                            Splinge reserves the right to change this Privacy Policy
                            from time to time. We will notify you about significant changes in the way we
                            treat personal information by sending a notice to the primary email address
                            specified in your account, by placing a prominent notice on our site, and/or by
                            updating any privacy information on this page. Your continued use of the Site
                            and/or Services available through this Site after such modifications will
                            constitute your: (a) acknowledgment of the modified Privacy Policy; and (b)
                            agreement to abide and be bound by that Policy.
                        </Typography>

                        <Typography variant="body1">&nbsp; </Typography>

                        <Typography variant="body1"><b>Contact Information</b> </Typography>

                        <Typography variant="body1">
                            Splinge welcomes your questions or comments regarding this
                            Statement of Privacy. If you believe that Splinge has not adhered to this
                            Statement, please contact Splinge at:
                        </Typography>

                        <a href="contact">Comments Form</a>


                        <Typography variant="body1">Effective as of Nov 11, 2022 </Typography>

                        <Typography variant="body1">&nbsp; </Typography>
                    </Container>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePrivacyClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>

            <AppBar position="fixed"  style={{ bottom: 0, top: 'auto'}} sx={{ backgroundImage: `linear-gradient(to right, rgb(0, 51, 81), rgb(80, 96, 112))` }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, alignItems: 'flex-start', alignContent: 'flex-start' }}>
                        {!isLoggedIn && (
                            <Button onClick={handleLogin} color="inherit">Login</Button>
                        )}
                    </Typography>
                    <Button onClick={handlePrivacy} color="inherit">Privacy</Button>
                    <Button onClick={handleTOS} color="inherit">Terms of Service</Button>
                </Toolbar>
                <Typography variant="body1" align="center" style={{ color: 'white', fontSize: '8px' }}>
                    © 2019-2025 Splinge, Inc. All rights reserved.
                </Typography>
                <Typography variant="body1" align="center" style={{ color: 'white', fontSize: '8px' }}>
                    Splinge, Inc owns all trademarks and logos.
                </Typography>
            </AppBar>
        </>
    );


}