import React, {useState} from 'react';
import { Container, Typography, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { _Contact } from '../apicalls/_contact';

export default function Contact() {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    function onSubmit() {
        //alert('Form Submitted');
        _Contact(name, email, message);
        navigate(`/confirm`);
      };
    

  return (
    <Container>
      <Typography variant="h4">Contact</Typography>
        <Typography variant="body1">
            Let us know what you think, or what you want to know.
        </Typography>
        <Typography variant="body1">
            If your question requires a response, please allow up to 48 hours.
        </Typography>
        <Typography variant="body1"></Typography>
        <TextField
            id="name"
            label="Name"
            type="text"
            required
            fullWidth
            margin="normal"
            onChange={(e) => setName(e.target.value)}
        />
        <TextField
            id="email"
            label="Email"
            type="email"
            required
            fullWidth
            margin="normal"
            onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
            id="message"
            label="Message"
            type="text"
            required
            fullWidth
            margin="normal"
            multiline
            rows={4}    
            onChange={(e) => setMessage(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
       
    </Container>
  );
}