import React, { useState, useEffect } from 'react';
import { Container, Typography, Button, TextField, Select } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { _Profile } from '../apicalls/_getprofile';
import { _SaveProfile } from '../apicalls/_saveprofile';
import { useStore } from '../store/store';
import { set } from 'react-hook-form';

export default function Profile() {
    //const lemail = useStore(state => state.lemail);
    const userId = useStore(state => state.userId);
    // console.log('User ID:', userId);
    //// console.log('Email:', lemail);

    const [fname, setFName] = useState('');
    const [lname, setLName] = useState('');
    const [email, setEmail] = useState('');
    const [add1, setAdd1] = useState('');
    const [add2, setAdd2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postcode, setPostcode] = useState('');
    const [country, setCountry] = useState('');
    const [telephone, setTelephone] = useState('');
    const [dob, setDob] = useState(null);

    useEffect(() => {
        // console.log(userId);
        _Profile(userId).then((result) => {
            // console.log(result);
            if (result !== null) {
                // console.log('Profile:', result);
                setFName(result.fname);
                setLName(result.lname);
                setEmail(result.email);
                setAdd1(result.add1);
                setAdd2(result.add2);
                setCity(result.city);
                setState(result.state);
                setPostcode(result.postcode);
                setCountry(result.country);
                setTelephone(result.telephone);
            } else {
                // console.log('Failed to get profile');
            }
        });
    }
        , []);

    function saveProfile() {
        // console.log('Save Profile');
        _SaveProfile(userId, fname, lname, email, add1, add2, city, state, postcode, country, telephone).then((result) => {
            // console.log(result);
            if (result) {
                // console.log('Profile Updated');
            } else {
                // console.log('Failed to update profile');
            }
        });
    };

    return (
        <Container sx={{ height: "100vh", overflow: "auto" }}>
            <Typography variant="h4">Profile</Typography>
            <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                value={fname}
                sx={{ marginBottom: 2 }}
                onChange={(e) => setFName(e.target.value)}
            />
            <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                value={lname}
                sx={{ marginBottom: 2 }}
                onChange={(e) => setLName(e.target.value)} />
            <TextField
                label="Contact Email"
                variant="outlined"
                fullWidth
                value={email}
                sx={{ marginBottom: 2 }}
                onChange={(e) => setEmail(e.target.value)} />
            <TextField
                label="Address 1"
                variant="outlined"
                fullWidth
                value={add1}
                sx={{ marginBottom: 2 }}
                onChange={(e) => setAdd1(e.target.value)} />
            <TextField
                label="Address 2"
                variant="outlined"
                fullWidth
                value={add2}
                sx={{ marginBottom: 2 }}
                onChange={(e) => setAdd2(e.target.value)} />
            <TextField
                label="City"
                variant="outlined"
                fullWidth
                value={city}
                sx={{ marginBottom: 2 }}
                onChange={(e) => setCity(e.target.value)} />

            <Container sx={{ overflow: "auto", direction: "row", marginBottom: 2 }}>

                <TextField
                    label="State/Province/Region"
                    variant="outlined"
                    fullWidth
                    value={state}
                    sx={{ width: "50%" }}
                    onChange={(e) => setState(e.target.value)} />
                <TextField
                    label="Postcode/Zip"
                    variant="outlined"
                    fullWidth
                    value={postcode}
                    sx={{ width: "50%" }}
                    onChange={(e) => setPostcode(e.target.value)} />
            </Container>

            <TextField
                label="Country"
                variant="outlined"
                fullWidth
                value={country}
                sx={{ marginBottom: 2 }}
                onChange={(e) => setCountry(e.target.value)} />

            <Container sx={{ overflow: "auto", direction: "row", marginBottom: 2}}>

            <Select
                label="Country Code"
                variant="outlined"
                native
                sx={{ width: "25%" }}  >
                <option value="+1">US (+1)</option>
                <option value="+44">UK (+44)</option>
                <option value="+61">Australia (+61)</option>
            </Select>
            <TextField
                label="Telephone"
                variant="outlined"
                value={telephone}
                sx={{ width: "75%" }}  
                onChange={(e) => setTelephone(e.target.value)} />

            </Container>

            <Typography variant="body1">&nbsp;</Typography>
            <Button variant="contained" color="primary" onClick={saveProfile}>Save</Button>
            <Typography variant="h3">&nbsp;</Typography>
            <Typography variant="h3">&nbsp;</Typography>
        </Container>
    );
}
