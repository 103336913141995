import React from "react";
import { Container,Typography } from '@mui/material';
import "../App.css";

export default function About() {
    return (
        <>
        <div className="pageBackground2">

        <Container sx={{ height: "100vh"}}>
            <Typography variant="h4">About</Typography>
            <Typography variant="h3">&nbsp;</Typography>
            <Typography variant="body1">
                Splinge is a platform that allows musicians to monetize their art in a way that is meaningful to them. We believe that musicians should be able to choose how they want to monetize their art, and we provide the tools to make that happen.
            </Typography>
            <Typography variant="h3">&nbsp;</Typography>
        </Container>
        </div>
        </>
    );
}   