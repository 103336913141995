import React, { useState } from 'react';
import { Container, Typography, Button, TextField } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { _Login } from '../apicalls/_login';
import { _GetId } from '../apicalls/_getId';
import { useStore } from '../store/store';
import { _GetLoc } from '../apicalls/_getloc';
import { set } from 'react-hook-form';

export default function Login() {
    const navigate = useNavigate();

    const { setIsLoggedIn } = useStore();
    const { setlEmail } = useStore();
    const { setUserId } = useStore();
    const { setAvatarID } = useStore();

    const [openDialog, setOpenDialog] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');

    const handleClose = () => {
        setOpenDialog(false);
    };

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    function onSubmit() {
        _Login(email, password).then((result) => {
            // console.log(result);

            if (result === true) {
                var userId: string = '';
                // console.log(result);
                _GetId(email).then((result) => {
                    // console.log(result);
                    setUserId(result);
                });
                setAvatarID(email.substring(1, 1));
                setIsLoggedIn(result);
                setlEmail(email);
                _GetLoc(email, 'Web App Login').then((result) => {
                    // console.log(result);
                    if (result !== null) {
                        // console.log('Location:', result);
                    } else {
                        // console.log('Failed to get profile');
                    }
                }
                );
                    navigate("/home");
                }
            else {
                        // console.log('failed to login');
                        setDialogMessage('Failed to login');
                setOpenDialog(true);
                    }
        }
        );
    };


    return (
        <>
            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>Message</DialogTitle>
                <DialogContent>
                    <Container></Container>
                    <Typography>{dialogMessage}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            <Container sx={{ height: "100vh" }}>
                <Typography variant="h4">Login</Typography>
                <Typography variant="h4">&nbsp;</Typography>
                <Typography variant="body1"></Typography>
                <TextField
                    id="email"
                    label="Email"
                    type="email"
                    required
                    fullWidth
                    margin="normal"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    id="password"
                    label="Password"
                    type="password"
                    required
                    fullWidth
                    margin="normal"
                    rows={4}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={onSubmit}>Submit</Button>
                <Typography variant="h4">&nbsp;</Typography>
                <Typography variant="body1"><a href="/createaccount">Create Account</a></Typography>

            </Container>
        </>
    );
}