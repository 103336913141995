import React from "react";
import { Container, Typography } from '@mui/material';

export default function Confirm() {
  return (
    <Container maxWidth="sm">
      <Typography variant="h3">&nbsp;</Typography>
      <Typography variant="h4">Thank you for your submission!</Typography>
      <Typography variant="body1"></Typography>
      <Typography variant="body1">We will get back to you as soon as possible.</Typography>
      <Typography variant="h3">&nbsp;</Typography>
    </Container>
  );
}