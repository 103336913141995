
import { create } from 'zustand'

interface useState {
    isLoggedIn: boolean
    setIsLoggedIn: (isLoggedIn: boolean) => void
    lemail: string                                  //Login email
    setlEmail: (email: string) => void
    userId: string
    setUserId: (userId: string) => void
    avatarID: string
    setAvatarID: (avatarID: string) => void
}

export const useStore = create<useState>()((set) => ({
    isLoggedIn: false,
    setIsLoggedIn: (isLoggedIn) => set({ isLoggedIn}),
    lemail: '',
    setlEmail: (lemail) => set({ lemail}),
    userId: '?',
    setUserId: (userId) => set({ userId}),
    avatarID: '?',
    setAvatarID: (avatarID) => set({ avatarID})
}))
