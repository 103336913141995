import React, {useEffect} from 'react';
import { Routes,  BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './controls/header';
import Footer from './controls/footer';
import { useStore } from './store/store';


import Home from './pages/home';
import About from './pages/about';
import NotFound from './pages/notfound';  
import Contact from './pages/contact';
import Confirm from './pages/confirm';  
import Login from './pages/login';
import CreateAccoount from './pages/createaccount';
import Account from './pages/account';
import Profile from './pages/profile';

import { _GetLoc } from './apicalls/_getloc';

function App() {
  const isLoggedIn = useStore(state => state.isLoggedIn);

  useEffect(() => {
    // conso le.log(userId);
    _GetLoc('', 'Web App').then((result) => {
      // console.log(result);
      if (result !== null) {
        // console.log('Location:', result);
      } else {
        // console.log('Failed to get profile');
      }
    });
  }
  , []);

  return (
      <Router>
        <div>
          <Header />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/home" element={<Home />} />
          <Route path="/confirm" element={<Confirm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/account" element={isLoggedIn ? <Account /> : <NotFound />} />
          <Route path="/profile" element={isLoggedIn ? <Profile /> : <NotFound />} />
          <Route path="/createaccount" element={<CreateAccoount />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <div>
          <Footer />
        </div>
      </Router>
  );
}

export default App;
