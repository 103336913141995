import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Avatar, Menu, MenuItem, Divider } from '@mui/material';
import {useStore} from '../store/store';

import Logo from '../assets/splinge_on_blue_500_250.png'

export default function Header() {
    const navigate = useNavigate();

    const avatarID = useStore(state => state.avatarID);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null); // State to store anchor element
    const open = Boolean(anchorEl);
    interface HandleClickEvent extends React.MouseEvent<HTMLDivElement> {}

    const handleClick = (event: HandleClickEvent) => {
        setAnchorEl(event.currentTarget); // Set anchorEl to clicked element
    }

    const handleClose = () => {
        setAnchorEl(null); // Close menu
    };    

    const handleLogout = () => {    
        useStore.setState({isLoggedIn: false});
        useStore.setState({avatarID: ''});
        handleClose();
        navigate('/');
    }

    interface HandleItemEvent extends React.MouseEvent<HTMLLIElement> {}

    const handleItem1 = (event: HandleItemEvent) => {
        // console.log('Item 1 clicked');
        navigate('/account');
        handleClose();
    }

    const handleItem2 = (event: HandleItemEvent) => {
        // console.log('Item 2 clicked');
        navigate('/profile');   
        handleClose();
    }

    const handleHome = () => {
        navigate(`/`);
    };

    const handleAbout = () => {
        navigate(`/about`);
    };

    const handleContact = () => {
        navigate(`/contact`);
    }

    return (

        <AppBar position="static" sx={{ backgroundImage: `linear-gradient(to right, rgb(3, 133, 255), rgb(80, 96, 112))` }}>

            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, alignItems: 'flex-start', alignContent: 'flex-start' }}>
                    <img src={Logo} alt="The Record Store Logo" height="100" />
                </Typography>
                <div onClick={handleClick}>
                <Avatar onClick={handleClick} sx={{ bgcolor: 'blue' }}>{avatarID}</Avatar>
                </div>
                <Button onClick={handleHome} color="inherit">Home</Button>
                <Button onClick={handleAbout} color="inherit">About</Button>
                <Button onClick={handleContact} color="inherit">Contact Us</Button>
            </Toolbar>

            <Menu 
                id="basic-menu"
                anchorEl={anchorEl} // Anchor the menu to the clicked button
                open={open}
                onClose={handleClose}
                >
                {useStore(state => state.isLoggedIn) ? (
                    <MenuItem onClick={handleItem2}>Profile</MenuItem>
                )
                :
                (
                    <MenuItem onClick={handleClose}></MenuItem>
                )
                }
                <Divider />
                {useStore(state => state.isLoggedIn) ? (
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                ) : (
                    <MenuItem onClick={() => { navigate('/login'); handleClose(); }}>Login</MenuItem>
                )}
                 </Menu>
        </AppBar>
    );

}