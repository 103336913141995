export async function _Contact(name: string, email: string, message: string) { 
    // console.log('Saving contact..');  
    const response = await fetch('https://api.splinge.com/contact', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            name: name,
            email: email,
            message: message
        })
    });

    if (response.ok) {  
        const data = await response.json();  
        // console.log("ok: ", data);
        return data;
    } else {
        // console.log('Failed to save contact.');
        return null;
    }
}