export async function _SaveLoc(
    userId: string,
    appName: string,
    city: string,
    country: string,
    hostname: string,
    ip: string,
    loc: string,
    latitude: string,
    longitude: string,
    region: string,
    timezone: string,
    postal: string,
    org: string,
    isp: string,)
{


var _body = JSON.stringify({ 
            userId: userId, 
            appName: appName,
            city: city,
            country: country,
            hostname: hostname,
            ip: ip, 
            loc: loc,
            latitude: latitude,
            longitude: longitude,
            region: region,
            timezone: timezone,
            postal: postal,
            org: org,
            isp: isp,
        });


// console.log(_body);

const response = await fetch('https://api.splinge.com/transaction', {
method: 'POST',
headers: {
'Content-Type': 'application/json; charset=UTF-8'
},
body: _body
});

if (response.ok) {
const data = await response.json();
// console.log(data);
return data;
} else {
// console.log('Failed to create account');
return null;
}
}