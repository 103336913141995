export async function _Profile(userId: string) { 
    // console.log('Logging in');  
    const response = await fetch('https://api.splinge.com/profile', {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            userId: userId,
        })
    });

    if (response.ok) {  
        const data = await response.json();  
        // console.log(data);
        return data;
    } else {
        // console.log('Failed to login');
        return null;
    }
}