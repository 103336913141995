import React from 'react';
import "../App.css";
import { useNavigate } from 'react-router-dom';
import { Container, Grid2, Typography, Box } from '@mui/material';
import { Cipher } from 'crypto';



export default function Home() {
    const navigate = useNavigate();
    return (
        <Container maxWidth={false} sx={{ height: "100vh", backgroundColor: "black"}}>  
            <Container sx={{ backgroundImage: "url('https://splinge.com/images/solo_performer.jpg')", backgroundSize: "cover" }}>
                <Box sx={{ height: "100vh", backgroundColor: "transparent"  ,overflow: "auto" }}>
                    <Box sx={{ height: "100vh" }}>
                        <Grid2 container spacing={3}>

                            <Grid2 size={{ xs: 5 }} sx={{ height: "100vh" }}>
                                <Typography variant="h4" style={{ color: "white" }}>
                                    Mission:
                                </Typography>
                                <Typography variant="h6" style={{ color: "white" }}>
                                    Splinge has a mission to help musicians monetize their art as they see fit.
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }}>
                                    &nbsp;
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }}>
                                    We believe that musicians should be able to sell their music directly to their fans.
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }}>
                                    &nbsp;
                                </Typography>
                                <Typography variant="h4" style={{ color: "white" }}>
                                    Record Store&trade;
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }}>
                                    Our first project is the Record Store&trade;. This will be released to the public in the coming weeks.
                                    The Record Store&trade; will allow musicians to sell their music directly to their fans.
                                    The Record Store&trade; will also allow fans to purchase music directly from their favorite musicians.
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }}>
                                    This moves the industry away from streaming services that keep the lions share of the money for themselves
                                    and towards a model where the musicians get their fare share of the money.
                                </Typography>
                                <Typography variant="h1" style={{ color: "white" }}>
                                    &nbsp;
                                </Typography>

                            </Grid2>
                            <Grid2 size={{ xs: 2 }}>


                            </Grid2>
                            <Grid2 size={{ xs: 5 }}>
                                <Typography variant="h4" style={{ color: "white" }}>
                                    What's next?
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }}>
                                    &nbsp;
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }}>
                                    We have several patent pending technologies that will make the music industry more artist-centric.
                                </Typography>
                                <Typography variant="body1" style={{ color: "white" }}>
                                    These will be announced and released in the near future.
                                </Typography>
                            </Grid2>

                        </Grid2>
                    </Box>
                </Box>
            </Container>
        </Container>
    );
}