export async function _CreateProfile(userId: string, fname: string, lname: string, email: string) { 
    // console.log('Creating Account');  

    var _body = JSON.stringify({userId: userId, fname: fname, lname: lname, email: email});
    // console.log(_body);

    const response = await fetch('https://api.splinge.com/createprofile', {
        method: 'POST',
        mode : 'cors',  
        headers: {
            'Access-Control-Allow-Origin': '*', 
            'Content-Type': 'application/json; charset=UTF-8'
        },
        body: _body
    });

    if (response.ok) {  
        const data = await response.json();  
        // console.log(data);
        return data;
    } else {
        // console.log('Failed to create account');
        return null;
    }
}