import React, { useState } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

export default function Account() {

    //We are going to have to get data.
    //We need to get a users credits
    //We need to get a users orders
    //We need to get a users account information
    //In the mean time we are going to use useState to store the data.

    const [credits, setCredits] = useState(0);
    const [orders, setOrders] = useState([]);
    const [account, setAccount] = useState({});

    const [dlgOpen, setDlgOpen] = useState(false);

    const handleDlgClose = () => {
        setDlgOpen(false);
    }

    const rows: GridRowsProp = [
        { id: 1, col1: '05/23/2024', col2: 'Look Sharp' },
        { id: 2, col1: '05/23/2024', col2: 'Drums and Wires' },
        { id: 3, col1: '05/24/2024', col2: 'White Music' },
    ];

    const columns: GridColDef[] = [
        { field: 'col1', headerName: 'Date', width: 150 },
        { field: 'col2', headerName: 'Album', width: 150 },
    ];


    return (
            <>
            <Dialog open={dlgOpen} onClose={handleDlgClose}>
                <DialogTitle>Buy Credits</DialogTitle>
                <DialogContent>
                    <Container>
                        <Typography variant="body1"><b>Buy Credits</b> </Typography>
                    </Container>
                </DialogContent>    
                <DialogActions>
                    <Button onClick={handleDlgClose}>Close</Button>
                </DialogActions>
            </Dialog>

        <Container sx={{ height: "100vh" }}>
            <Typography variant="h4">Account</Typography>
            <Typography variant="h3">&nbsp;</Typography>
            <Typography variant="body1">
                <div className="container">
                    <div className="row">
                        <div className="col-2">
                            <Typography variant="body1">Credits: </Typography>
                        </div>
                        <div className='col-2'>
                            <Typography variant="body1">{credits}</Typography>
                        </div>
                        <div className="col-3">
                            <Button variant="contained" color="primary" onClick={() => setDlgOpen(true)}>Add Credits</Button>
                        </div>
                    </div>
                </div>            
            </Typography>
            <Typography variant="h3">&nbsp;</Typography>
                <div style={{ height: 300, width: '100%' }}>
                    <DataGrid rows={rows} columns={columns} />
                </div>

            <Typography variant="h3">&nbsp;</Typography>
        </Container>
        </>
    )
}
