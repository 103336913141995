import { _SaveLoc } from './_saveLoc';

export async function _GetLoc(userId: string, appName: string) { 
    var _fetch = 'https://ipinfo.io/json'; 
    const response = await fetch(_fetch, {
        method: 'GET',
    });

    if (response.ok) {  
        const data = await response.json();  
        //console.log(data);
        //return data;
        var userId: string = userId;
        var appName: string = appName;
        var city: string = data.city;
        var country: string = data.country;
        var hostname: string = data.hostname;
        var ip: string = data.ip;
        var loc: string = data.loc;
        var latitude: string = data.latitude;
        var longitude: string = data.longitude;
        var region: string = data.region;
        var timezone: string = data.timezone;
        var postal: string = data.postal;
        var org: string = data.org;
        var isp: string = data.isp;

    _SaveLoc(userId, appName, city, country, hostname, ip, loc, latitude, longitude, region, timezone, postal, org, isp);
    
} else {
        // console.log('Failed to login');
        return null;
    }
}