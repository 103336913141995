import React from "react";
import { Container, Typography } from '@mui/material';

export default function NotFound() {
    return (
                <Container sx={{ height: "100vh" }}>
                    <Typography variant="h4">404 - Not Found</Typography>
                    <Typography variant="h3">&nbsp;</Typography>
                    <Typography variant="body1">The page you are looking for does not exist.</Typography>
                </Container>
    );
}